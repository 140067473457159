:root {
  /*PRIMARY COLORS*/
  --primary-orange: #E80024;
  --primary-pink: #E9A2A7;
  --primary-purple: #891059;
  --primary-green: #00817E;
  /*ADITIONAL COLORS*/
  --aditional-green: #004D4B;
  --aditional-yellow: #F79E22;
  --aditional-orange: #EB5757;
  /*SECONDARY COLORS*/
  --secondary-green: #26827D;
  --secondary-green3: #53D0C9;
  --secondary-green4: #2abfc9;
  --secondary-green5: #1AD9E5;
  --secondary-celeste2: #9BD5D9;
  --secondary-celeste3: #19D9E5;
  --secondary-celeste4: #F2FEFF;
  --secondary-blue5: #2054bb;
  /*GRAY COLORS*/
  --black: #000;
  --default-gray: #848484;
  --gray: #CBCCCC;
  --gray-opacity60: rgba(203, 204, 204, 60%);
  --gray2: #F1F1F1;
  --gray3: #808080;
  --gray4: #666;
  --light-gray: #C5C6D1;
  --light-gray2: #F1F2F4;
  --light-gray3: #F9F9F9;
  --light-gray4: #F4F6FA;
  --white: #FEFEFE;
  --white2: #FDFDFD;
  /*TOASTERS*/
  --toaster-success: #0B4670;
  --toaster-warning: #F9ECD9;
  --toaster-error: #E9A2A7;
  --primary-yellow: #AC7C37;
  /*Others*/
  --secondary-yellow: #FFCC19;
  --secondary-orange: #F64952;
  --red: #D60D0D;
  --red2: #B3001B;
  --gray-input: #858482;
}

* {
  font-family: 'Montserrat', Verdana, sans-serif;
  box-sizing: border-box;
}

html {
  background-color: var(--light-gray4) !important;
}

html,
body {
  height: calc(100vh - 101px);
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-size: .8rem !important;
  background-color: var(--light-gray4) !important;
}

footer {
  position: sticky;
  top: calc(100vh - 53px);
  width: 100%;
}

body,
.ui-widget,
.form-control {
  font-family: 'Montserrat', Verdana, sans-serif;
}

b,
p,
h1,
h2,
h3,
h4,
h5,
h6,
.title,
strong,
small,
em,
u,
a,
li,
ul,
div,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
label,
span {
  font-family: 'Montserrat', Verdana, sans-serif !important;
}

hr {
  color: rgba(0, 0, 0, .5);
}

/*Remove default black outline on selected elements*/
*:focus {
  outline: 0 !important;
}

.text-wrap {
  overflow-wrap: break-word !important;
}

/*Cursor*/
.clickable {
  cursor: pointer !important;
}

/* ----------------- CHAT BUTTON CUSTOM STYLES -------------------- */

.embeddedServiceHelpButton {
  display: none !important;
}

img[embeddedService-chatHeader_chatHeader] {
  margin-right: 8px;
  max-height: 23px;
  max-width: 100px;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage img,
.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage .uiImage {
  width: 30px;
  height: 7px;
  border-radius: 0%;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText>.message {
  margin: 0px 0px;
}

.embeddedServiceLiveAgentStateChat .endChatContainer {
  visibility: hidden;
}

h2[embeddedService-chatHeader_chatHeader] {
  color: white;
}

/* ---------------------------------------- AKKY CUSTOM STYLES ---------------------------------------- */

.basic-card {
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(197, 198, 209, 0.4);
}

.basic-content {
  margin-top: 99px;
}

.principal-container {
  background-color: var(--white) !important;
}

@media (max-width: 767px) {
  .basic-content {
    margin-top: 64px;
  }
}

.border-card {
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(197, 198, 209, 0.4);
}

.border-gray {
  border: 2px solid lightgray !important;
}

.bg-white {
  background-color: white !important;
}

.bg-gray-3 {
  background-color: var(--light-gray3) !important;
}

.bg-green {
  background: var(--primary-green);
}

.bg-aqua {
  background-color: var(--secondary-green3) !important;
}

.bg-yellow {
  background: var(--secondary-yellow);
}

/*-----------------------  HEADERS AND TEXT --------------------------------*/
.silver {
  color: #A8A9AA !important;
}

.gray {
  color: #848484 !important;
}

.white {
  color: var(--white) !important;
}

.yellow {
  color: var(--aditional-yellow) !important;
}

.aqua {
  color: var(--secondary-green3) !important;
}

.text-green {
  color: var(--primary-green) !important;
}

.text-default-gray {
  color: var(--default-gray);
}

.gray3 {
  color: var(--gray3) !important;
}

.black {
  color: black !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-login {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  font-family: 'Montserrat', Verdana, sans-serif;
  font-style: normal;
  color: var(--primary-purple);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--black);
  font-style: normal;
  font-weight: normal;
}

h5,
.h5 {
  font-size: 20px !important;
  line-height: 1em;
}

h4,
.h4 {
  font-size: 24px !important;
  line-height: 32px;
}

h3,
.h3 {
  font-size: 40px !important;
  line-height: 48px;
}

h2,
.h2 {
  font-size: 48px;
  line-height: 56px;
}

h1,
.h1 {
  font-size: 40px !important;
  line-height: 48px;
  margin: 0px 0px 20px 0px;
}

/* 16px */
.fs-l {
  font-size: 1rem !important;
}

/* 14 px */
.fs-m {
  font-size: .875rem !important;
}

/* 12 px */
.fs-md {
  font-size: .75rem !important;
}

/* 8px */
.fs-sm {
  font-size: .5rem !important;
}

.fs-xl {
  font-size: 1.5rem !important;
}

.fs-xxl {
  font-size: 2.5rem !important;
}

.fs-2rem {
  font-size: 2rem;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-nowrap {
  white-space: nowrap;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-justify {
  text-align: justify !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-underline {
  text-decoration: underline !important;
}

.none-underline {
  text-decoration: none;
}

.underline:hover {
  text-decoration: underline !important;
  color: var(--gray4) !important;
}

.gray-hover:hover {
  text-decoration: none;
  color: var(--gray4) !important;
}

.title {
  font-size: 28px !important;
  font-weight: bold;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}

/*----------BUTTONS----------*/
.btn-sm {
  padding: 8px 15px !important;
  font-size: 0.8rem !important;
  line-height: 1em !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

/*Primary*/
.btn.btn-primary {
  border: none !important;
  background-color: var(--primary-orange) !important;
  background: var(--primary-orange) !important;
  box-shadow: none !important;
  color: white !important;
  border-radius: 60px !important;
  font-weight: bold !important;
  font-size: .875rem !important;
  line-height: 1rem !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: white !important;
  padding: 14px 24px !important;
  /* 1rem 1.5rem; */
  text-decoration: none !important;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background-color: var(--red2) !important;
}

.btn.btn-primary:disabled,
.btn.btn-primary[disabled] {
  background-image: none !important;
  box-shadow: none !important;
  background-color: var(--light-gray) !important;
  color: var(--gray4) !important;
}

.btn.btn-primary:disabled,
.btn.btn-primary[disabled]:hover {
  border: none !important;
}

/*---------- Secondary----------*/
.btn.btn-secondary {
  background-color: var(--white2) !important;
  border: 1px solid var(--light-gray) !important;
  box-sizing: border-box !important;
  color: var(--primary-green) !important;
  border-radius: 100px !important;
  font-size: .875rem !important;
  line-height: 1rem !important;
  font-weight: bold !important;
  text-decoration: none !important;
  height: 38px !important;
}

.btn-sm.btn-secondary {
  background-color: var(--white2) !important;
  border: 1px solid var(--light-gray) !important;
  box-sizing: border-box !important;
  color: var(--primary-green) !important;
  border-radius: 100px !important;
  font-size: .875rem !important;
  line-height: 1rem !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 8px 20px !important;
  text-decoration: none !important;
}

.btn.btn-secondary.black {
  color: black !important;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:focus {
  background-color: var(--white2) !important;
  color: var(--primary-green) !important;
}

.btn.btn-secondary.black:hover,
.btn.btn-secondary.black:active,
.btn.btn-secondary.black:focus {
  color: black !important;
}

.btn.btn-secondary:disabled,
.btn.btn-secondary[disabled] {
  background-color: var(--light-gray) !important;
  background-image: none !important;
  box-shadow: none !important;
  color: var(--gray4) !important;
}

.btn.btn-secondary:disabled,
.btn.btn-secondary[disabled]:hover {
  border: none !important;
}

.btn-sm.btn-secondary:hover,
.btn-sm.btn-secondary:active,
.btn-sm.btn-secondary:focus {
  background-color: var(--white2) !important;
  color: var(--primary-green) !important;
  box-shadow: none !important;
}

/*---------- Info ----------*/
.btn.btn-info {
  border: none;
  background-color: var(--primary-green);
  background: var(--primary-green);
  box-shadow: none;
  color: white;
  border-radius: 60px;
  font-weight: bold;
  font-size: .875rem;
  line-height: 1rem;
  text-align: center;
  color: white;
  padding: 14px 24px;
  text-decoration: none;
}

.btn.btn-info:hover {
  background: var(--aditional-green) !important;
  color: white !important;
}

.btn.btn-info:focus,
.btn.btn-info:active {
  background: var(--primary-green) !important;
  color: white !important;
}

.btn.btn-info:focus {
  box-shadow: 4px 4px 8px rgb(197 198 209 / 24%), -4px -4px 8px rgb(254 254 254 / 40%), inset 0px 0px 8px rgb(197 198 209 / 40%) !important;
}

.btn.btn-info:disabled,
.btn.btn-info[disabled] {
  background-image: none !important;
  box-shadow: none !important;
  background-color: var(--light-gray) !important;
  color: var(--gray4) !important;
}

.btn.btn-info:disabled,
.btn.btn-info[disabled]:hover {
  border: none;
}

/*---------- Light ----------*/
.btn.btn-light {
  border: none;
  background-color: var(--secondary-blue5);
  background: var(--secondary-blue5);
  box-shadow: none;
  color: white;
  border-radius: 60px;
  font-weight: bold;
  font-size: .875rem;
  line-height: 1em;
  text-align: center;
  color: white;
  padding: 1rem 1.5rem;
  text-decoration: none;
  border: 1px #3386e2 solid;
}

.btn.btn-light:hover,
.btn.btn-light:active,
.btn-light:focus {
  background: var(--secondary-blue5);
  color: white;
}

.btn.btn-info:disabled,
.btn.btn-info[disabled] {
  background-color: var(--light-gray);
  background-image: none;
  box-shadow: none;
  color: var(--light-gray);
}

.btn.btn-info:disabled,
.btn.btn-info[disabled]:hover {
  border: none;
}

/*---------- Warning ----------*/
.btn.btn-warning {
  color: white;
  border-radius: 60px;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  color: white;
  padding: 5px 24px;
  text-decoration: none;
}

/*bdge*/
.badge {
  border-radius: 21px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 1em !important;
  text-align: center !important;
  background-image: none;
  text-transform: uppercase !important;
}

/*---------- inputs ----------*/

.input-group.floating-label {
  position: relative !important;
  z-index: 99 !important;
  align-items: center !important;
}

.input-group.floating-label .input-group-append {
  position: absolute !important;
  right: 0 !important;
  margin-right: 2px !important;
  z-index: 100 !important;
}

.input-group.floating-label .form-input {
  background: transparent !important;
}

.floating-label {
  position: relative;
  display: inline-block;
}

.form-control {
  border-radius: 20px !important;
  border: 1px solid var(--black) !important;
}

.floating-label>select:focus,
.floating-label>select:hover,
.floating-label>input:focus:not(:disabled),
.floating-label>input:hover:not(:disabled) {
  border: 1px solid var(--secondary-green4) !important;
  box-shadow: none !important;
}

.form-control:disabled {
  border: 1px solid gray !important;
  background-color: var(--light-gray);
}

.floating-label>label,
.floating-label.w-100>label,
.floating-label.sizeBox>label {
  position: absolute;
  bottom: 12px;
  transition: all 0.22s ease-in;
  pointer-events: none;
  padding: 0;
  box-shadow: 0px 10px 0px white inset;
  font-size: .75rem;
  font-weight: bold;
  line-height: 1rem;
  margin: 0 0 0 16px;
  text-transform: uppercase;
  left: 0px;
}

.floating-label>input.is-invalid,
.floating-label>select.is-invalid {
  border: 1px solid var(--red) !important;
  background-image: none !important;
}

.floating-label>input:focus+label,
.floating-label>select:focus+label,
.floating-label>input:not(:placeholder-shown)+label,
.floating-label>select:not(:placeholder-shown)+label {
  box-shadow: 0px 10px 0px white inset;
  transform: translateY(-18px);
}

.up-label {
  transform: translateY(-18px);
}

/*--------Green checkbox ------*/
.form-check-input {
  transform: scale(1.5);
}

.form-check-input:checked[type="radio"] {
  border-color: var(--secondary-green4) !important;
  background-color: var(--secondary-green4) !important;
}

.form-check-input:focus[type="radio"] {
  box-shadow: 0 0 0 0.25rem rgb(0 174 237 / 25%) !important;
}

.form-check {
  min-height: auto !important;
}

.checkbox-accepted {
  width: 15px;
  height: 15px;
}

.checkbox-accepted:checked {
  background-color: var(--primary-green) !important;
  border-color: var(--primary-green) !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0 !important;
}


/*--------Green checkbox ------*/

.error-container {
  color: var(--red);
  padding: 0 15px;
  font-weight: bold;
}

.suffix-icon-input {
  position: absolute;
  right: 22px;
  top: 8px;
}

.prefix-icon-input {
  position: absolute;
  top: 9px;
  left: 15px;
  transform: scale(1);
}

.domain-name {
  overflow-wrap: break-word;
}

.word-break-all {
  word-break: break-all !important;
}

.word-break-wrap {
  word-wrap: break-word;
  word-break: break-word;
}

.subtitle-primary {
  font-family: "Montserrat";
  font-size: 14px;
  color: black;
  font-weight: bold;
}

.subtitle-primary-regular {
  font-family: "Montserrat";
  font-size: 14px;
  color: black;
  font-weight: normal;
}

.max-w-576 {
  max-width: 576px !important;
}

.custom-dd-menu {
  width: -webkit-fill-available;
}

.vertical-align-middle {
  vertical-align: middle;
}

.bg-accordion-body {
  background-color: var(--light-gray4);
}

.list-style-none {
  list-style: none;
}

.custom-dd-items {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 20vh;
}

.d-contents {
  display: contents;
}

.dialog-auto {
  max-width: 500px;
  border-radius: 5px;
}

.font-weight-700 {
  font-weight: 700 !important;
  font-size: 0.75rem !important;
}

.accordion-button:focus {
  border-color: white !important;
  outline: 0;
  box-shadow: none !important;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

@media (max-width: 386px) {
  #akky-logo {
    max-width: 100px !important;
  }
}

@media (max-width: 380px) {
  .d-contents-ul {
    display: contents !important;
  }
}

.price {
  white-space: nowrap;
}

.hr-custom {
  margin: 1rem 0 !important;
  color: inherit !important;
  border: 0 !important;
  opacity: .25 !important;
  margin-top: 0px !important;
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
  font-size: 1.25rem;
}

.description-field {
  font-size: 70%;
  width: 100%;
  color: var(--gray4);
  font-weight: bold;
  padding-left: 0.25rem;
}

.dropdown-required>.error-container {
  contain: size !important;
}

#personType>.set-custom {
  margin-bottom: 0px !important;
}

.purple {
  color: var(--primary-purple);
}

.full-width-dialog {
  width: -webkit-fill-available !important;
  height: 100vh !important;
}

/*---------- fonts ----------*/
@media (min-width: 768px) {
  @font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
}